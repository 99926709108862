import * as PHONE_LIB from 'libphonenumber-js';
import PropTypes      from 'prop-types';
import React          from 'react';

import { ModalBtnRow }       from '../modal-btn-row';
import { ModalWrapper }      from '../modal-wrapper';
import { TextField }         from '../../common/inputs/text-field';
import { Message }           from '../../common/message';
// import { SelectField }          from '../../common/inputs/select-field';
import * as Actions          from '../../../context/ctx-actions';
import { useGlobalCtx }      from '../../../context/ctx-hook';
import * as MixPanel         from '../../../helpers/mixpanel';
import { checkForAnyErrors } from '../../../helpers/pure-functions';
import * as Validations      from '../../../helpers/validations';
// import * as Options             from '../../../helpers/constants/options-constants';
import { callUpdatePhoneV3 }   from '../../../network/quicken-id-calls';


import * as S from './phone-modal.module.scss';

export const QKN_PHONE_VALIDATIONS = {
  phone:    Validations.validatePhone,
  password: Validations.validateUserPassword
};

export const FORM_MFA_VALIDATIONS = {
  mfa: Validations.validateMFA,
  mfaCode: Validations.validateMFACode,
};


const phoneErrorChecker = (code, extData, errObj) => {
  switch (code.toUpperCase()) {
    case 'QCS-0400-8':
      if (extData?.parameterName === 'mfaCode') {
        return { ...errObj, mfaCode: 'Please enter a valid 6 digit code' };
      } else {
        return {...errObj, phone: 'Phone number is invalid'};
      }
    case 'QCS-0422':
      return {...errObj, phone: 'Please enter a different phone number'};

    case 'QCS-0401-7':
      return {...errObj, password: 'Your password is invalid'};

    case 'QCS-0401-9':
      return {...errObj, form: 'Account locked due to failed attempts'};

    default:
      return {...errObj, form: 'GENERIC_ERROR'};
  }
};

const PhoneModal = ({onClose}) => {
  const { globalDispatch, state: { 
    isDisabled, 
    security: { 
      phone, 
      mfa, 
      mfaCode, 
      // mfaSentChannel, 
      mfaSentTo, 
      // quickenId
    }}} = useGlobalCtx();
  let resendInFlight = false;


  const [form, setForm] = React.useState({
    errors: {phone: '', password: '', mfa: '', mfaCode: '', form: ''},
    values: {
      phone:    phone ? PHONE_LIB.parsePhoneNumberFromString(phone).formatNational() : '',
      mfa,
      mfaCode,
      password: ''
    }
  });

  const CURRENT_PHONE = React.useMemo(
    () => phone ? PHONE_LIB.parsePhoneNumberFromString(phone).formatNational() : 'N/A',
    [phone]
  );

  const clearFormError = React.useCallback(() => {
    setForm(({values, errors}) => ({
      values: {...values},
      errors: {...errors, form: ''}
    }));
  }, []);

  const handleChange = React.useCallback(({type, target: {name, value}}) => {
    if (type === 'blur') value = value.trim();
    setForm(({values, errors}) => ({
      values: {...values, [name]: value},
      errors: {...errors, [name]: QKN_PHONE_VALIDATIONS[name](value)}
    }));
  }, []);

  // const handleSelection = React.useCallback(({name, value}) =>
  //   setForm(({values, errors}) => ({
  //     values: {...values, [name]: value},
  //     errors: {...errors, [name]: FORM_MFA_VALIDATIONS.mfa(value)}
  //   })),
  // []
  // );

  const handlePhoneChange = React.useCallback(({type, target: {value}}) => {
    setForm(({values, errors}) => ({
      values: {...values, phone: new PHONE_LIB.AsYouType('US').input(value).trim()},
      errors: {...errors, phone: type === 'blur' ? QKN_PHONE_VALIDATIONS.phone(value) : errors.phone}
    }));
  }, [setForm]);

  const handleResendCode = React.useCallback(async () => {
    if (resendInFlight) { return; }
    const formData = document.querySelector('form');
    const password = formData.elements.password.value.trim();
    const newPhone = formData.elements.phone.value.trim();
    const mfaChannel = 'SMS';
    const mfaCode = '';

    const ERROR_OBJ = {
      password: QKN_PHONE_VALIDATIONS.password(password),
      phone: QKN_PHONE_VALIDATIONS.phone(newPhone),
      mfaChannel: FORM_MFA_VALIDATIONS.mfa('SMS'),
      mfaCode: FORM_MFA_VALIDATIONS.mfaCode(mfaCode),
      form: ''
    };

    try {
      globalDispatch(Actions.setCtxField('isDisabled', true));
      resendInFlight = true;
      await callUpdatePhoneV3(newPhone, password, mfaChannel, mfaCode);
      resendInFlight = false;
      globalDispatch(Actions.setCtxField('isDisabled', false));
    } catch (err) {
      MixPanel.error(err, MixPanel.MIX_PANEL_IDS.PASSWORD);
      setForm(({ values }) => ({
        values: { ...values },
        errors: { ...ERROR_OBJ, form: 'GENERIC_ERROR' }
      }));
      resendInFlight = false;
      globalDispatch(Actions.setCtxField('isDisabled', false));
    }
  });

  const handleMfaCodeChange = React.useCallback(({type, target: {name, value}}) => {
    const IS_BLUR = type === 'blur';
    if (IS_BLUR) value = value.trim();

    // Had to add this as paste wasn't clearing form errors
    const submitBtn = document.querySelector('[data-testid="PHONE_FORM_SUBMIT"]');
    submitBtn.disabled = false;

    setForm(({values, errors}) => ({
      values: {...values, [name]: value},
      errors: {...errors, [name]: FORM_MFA_VALIDATIONS.mfaCode(value)}
    }));
  }, []);

  // const OPTIONS = React.useMemo(() => phone ? Options.MFA_OPTIONS : Options.MFA_DISABLED, [phone]);

  const setMfaFields = (action) => {
    const mfaCodeLabel = document.querySelector('[data-testid="MFA_CODE_LABEL"]');
    const mfaCodeField = document.querySelector('[data-testid="MFA_CODE_INPUT"]');
    const mfaResendMsg = document.querySelector('[data-testid="MFA_RESEND_MSG"]');
    const submitBtn = document.querySelector('[data-testid="PHONE_FORM_SUBMIT"]');

    if(action === 'enable') {
      mfaCodeLabel.style.display = 'initial';
      mfaCodeField.style.display = 'initial';
      mfaResendMsg.style.display = 'block';
      globalDispatch(Actions.setCtxField('isDisabled', false));
      submitBtn.innerHTML = 'Submit';
      submitBtn.disabled = true;
    } 
    
    if(action === 'disable') {
      mfaCodeLabel.style.display = 'hidden';
      mfaCodeField.style.display = 'hidden';
      mfaResendMsg.style.display = 'hidden';
      globalDispatch(Actions.setCtxField('isDisabled', false));
      submitBtn.innerHTML = 'Continue';
      submitBtn.disabled = false;
    }
  };

  const handleSubmit = React.useCallback(async e => {
    e.preventDefault();
    const phone      = e.target.elements.phone.value.trim();
    const password   = e.target.elements.password.value.trim();
    // const mfaChannel = e.target.elements.mfa.value.trim();
    const mfaCode    = e.target.elements.mfaCode.value.trim();

    const ERROR_OBJ = {
      phone:      QKN_PHONE_VALIDATIONS.phone(phone),
      password:   QKN_PHONE_VALIDATIONS.password(password),
      // mfaChannel: FORM_MFA_VALIDATIONS.mfa(mfaChannel),
      mfaCode:    FORM_MFA_VALIDATIONS.mfaCode(mfaCode),
      form:       ''
    };

    setForm({
      values: { phone, password, mfa, mfaCode},
      errors: ERROR_OBJ
    });

    if (!checkForAnyErrors(Object.values(ERROR_OBJ))) {
      try {
        globalDispatch(Actions.setCtxField('isDisabled', true));

        const PHONE  = PHONE_LIB.parsePhoneNumber(phone, 'US').number;
        const apiResp = await callUpdatePhoneV3(PHONE, password, 'SMS', mfaCode);
        const data = apiResp.data;
        
        if (apiResp.status == '202') {
          setMfaFields('enable');
          if (data.mfaChannel === 'EMAIL') {
            globalDispatch(Actions.mergeMainStateObj({
              security: {
                mfaSentChannel: data.mfaChannel,
                mfaSentTo: data.email
              }
            }));
          } else if (data.mfaChannel === 'SMS' || data.mfaChannel === 'VOICE') {
            globalDispatch(Actions.mergeMainStateObj({
              security: {
                mfaSentChannel: data.mfaChannel,
                mfaSentTo: data.phone
              }
            }));
          }
        } else {
          MixPanel.track(MixPanel.MIX_PANEL_IDS.PHONE);
          globalDispatch(Actions.mergeMainStateObj({
            isDisabled: false,
            security: {
              phone: data.phone,
              mfa: phone ? mfa : 'SMS',
              mfaSentChannel: '',
              mfaSentTo: ''
            },
            alert: {
              type: 'SUCCESS',
              messages: ['Your phone number was changed successfully.']
            }
          }));

          onClose();
        }
      } catch (err) {
        console.log(err);
        MixPanel.error(err, MixPanel.MIX_PANEL_IDS.PHONE);
        const CODE = err?.response?.data?.errors?.[0]?.code ?? '';
        const extData  = err?.response?.data?.errors?.[0]?.extData ?? false;
        
        setForm(({values}) => ({
          values: {...values},
          errors: phoneErrorChecker(CODE.toUpperCase(), extData, ERROR_OBJ)
        }));

        setMfaFields('disable');

        globalDispatch(Actions.setCtxField('isDisabled', false));
      }
    }
  }, [globalDispatch, onClose, mfa]);

  const HAS_ERROR = React.useMemo(
    () => Object.values(form.errors).some(v => v !== ''),
    [form.errors]
  );

  const IS_GENERIC_ERROR = form.errors.form === 'GENERIC_ERROR';

  return (
    <ModalWrapper heading="Change Phone" onClose={onClose}>
      <div className={S.modalBody}>
        {form.errors.form && (
          <Message
            messages={IS_GENERIC_ERROR ? [] : [form.errors.form]}
            type={IS_GENERIC_ERROR ? 'GENERIC_ERROR' : 'ERROR'}
          />
        )}

        <form onSubmit={handleSubmit}>
          <div className={S.phoneForm}>

            <div className={S.currentLabel}>
              <h3 className={S.contentLabel}>Current Phone Number</h3>
              <p
                className={S.contentText}
                data-testid="PHONE_FORM_CURRENT"
              >{CURRENT_PHONE}</p>
            </div>


            <TextField
              label="New Phone Number"
              name="phone"
              id="PHONE_FORM_NUMBER"
              placeholder="(###) ###-####"
              className={S.phoneInput}
              error={form.errors.phone}
              value={form.values.phone}
              disabled={isDisabled}
              onChange={handlePhoneChange}
              onBlur={handlePhoneChange}
              onFocus={clearFormError}
              maxLength={form.values.phone.startsWith('1') ? '16' : '14'}
            />

            <TextField
              label="Current Password"
              name="password"
              type="password"
              id="PHONE_FORM_PASSWORD"
              placeholder="Enter Current Password"
              className={S.passwordInput}
              error={form.errors.password}
              value={form.values.password}
              disabled={isDisabled}
              onChange={handleChange}
              onBlur={handleChange}
              onFocus={clearFormError}
            />
          </div>

          <div className={S.phoneMfaContainer}>
            <p className={S.mfaCodeMsg}>To help keep your account secure, we need to verify your identity with a two-factor authentication code. 
              <br></br>
              <small>Please note: Your code will be sent to the new phone number.</small>
            </p>

            <p data-testid="MFA_RESEND_MSG" className={S.warning}>We sent a code to
              {' '+mfaSentTo+' '}
              <span className={S.resendText}>Didn’t receive it?
                <span
                  className={S.resendLink}
                  onClick={handleResendCode}
                  onKeyUp={handleResendCode}
                  role="button"
                  disabled={isDisabled}
                  tabIndex={0}
                > Resend code </span>
              </span>
            </p>


            <div className={S.mfaInputContain}>
              {/* <SelectField
                label="How would you like to recieve your code?"
                name="mfa"
                id="MFA_FORM_SELECTION"
                className={S.mfaMethodSelect}
                error={form.errors.mfa}
                value={mfaSentChannel ? 'Phone' : 'Phone'}
                value="Phone"
                disabled={true}
                onChange={handleSelection}
                optionList={OPTIONS}
                onFocus={clearFormError}
              /> */}

              <TextField
                label="Enter the 6-digit code we sent you"
                name="mfaCode"
                type="number"
                maxLength="6"
                id="MFA_CODE"
                className={S.mfaCodeInputHidden}
                error={form.errors.mfaCode}
                value={form.values.mfaCode}
                disabled={isDisabled}
                onChange={handleMfaCodeChange}
                onInput={handleMfaCodeChange}
                onBlur={handleMfaCodeChange}
                onFocus={clearFormError}
              />
            </div>
          </div>

          <ModalBtnRow
            form="PHONE_FORM"
            isDisabled={isDisabled}
            hasError={HAS_ERROR}
            onCancel={onClose}
          />
        </form>
      </div>
    </ModalWrapper>
  );
};

PhoneModal.displayName = 'PhoneModal';
PhoneModal.propTypes   = {
  onClose: PropTypes.func.isRequired
};

export { PhoneModal };
